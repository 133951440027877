import React from 'react'
import * as S from './Posts.styles'
import T from 'prop-types'

import Post from './_components/Post'

function Posts({ posts }) {
  return (
    <S.PostsGrid container component="ul" spacing={{ sm: 4 }}>
      {posts.length === 0 && (
        <S.NoPostText>
          Mince ! Aucun article ne correspond à tous ces critères de recherche.
          <br /> Essayez de sélectionner moins de tags à la fois.
        </S.NoPostText>
      )}
      {posts.map((post, index) => (
        <Post variant="outlined" key={index} post={post} />
      ))}
    </S.PostsGrid>
  )
}

Posts.propTypes = {
  posts: T.arrayOf(T.shape({})),
}

export default Posts
