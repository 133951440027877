import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoints } from 'utils/breakpoints'

export const PostGrid = styled(Grid)`
  position: relative;
  border-radius: 4px;
  list-style: none;
  transform: scale(1);
  margin: 0 16px 24px;
  transition: all 0.1s ease-in-out;

  @media ${breakpoints.sm} {
    margin: 0;
  }

  @media ${breakpoints.md} {
    &:hover {
      transform: scale(1.02);
    }
  }
`

export const Link = styled.a`
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  display: block;
  position: relative;
  text-decoration: none;
  color: inherit;

  &::after {
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.05;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }
`

export const Image = styled(GatsbyImage)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const Text = styled.div`
  padding: 16px;
`

export const Title = styled.h2`
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 16px;
`
