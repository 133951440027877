import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import T from 'prop-types'
import store from 'store2'

import Tag from 'components/Tag'
import * as S from './TagsSelector.styles'

function TagsSelector({ onSelectedTagChange, tags }) {
  const [selectedTag, setSelectedTag] = useState(
    () => store.get('selected-tag') || 'tutorials'
  )

  const handleChangeSelectedTag = (tag) => {
    store.set('selected-tag', tag)
    setSelectedTag(tag)
  }

  const handleTagClick = (slug) => () => handleChangeSelectedTag(slug)

  useEffect(() => {
    onSelectedTagChange(selectedTag)
  }, [selectedTag, onSelectedTagChange])

  return (
    <S.List center>
      <Tag
        tag={{ name: 'Tutos', slug: 'tutorials' }}
        big
        clickable
        onClick={handleTagClick('tutorials')}
        outlined={!R.equals(selectedTag, 'tutorials')}
      />
      {tags.map(({ node: tag }, index) => {
        if (tag.slug === 'tutos') {
          return null
        }
        return (
          <Tag
            big
            clickable
            key={index}
            onClick={handleTagClick(tag.slug)}
            outlined={!selectedTag.includes(tag.slug)}
            tag={tag}
          />
        )
      })}
    </S.List>
  )
}

TagsSelector.propTypes = {
  onSelectedTagChange: T.func,
  tags: T.arrayOf(
    T.shape({
      node: T.shape({
        slug: T.string,
      }),
    })
  ),
}

export default TagsSelector
