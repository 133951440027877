import React from 'react'
import T from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function HomepageMetatags({ title, description }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const siteUrl = data.site.siteMetadata.siteUrl

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline: title,
    inLanguage: 'fr-FR',
    mainEntityOfPage: siteUrl,
    description: title,
    name: title,
    creator: {
      '@type': 'Person',
      name: 'Pomme',
    },
    publisher: {
      '@type': 'Organization',
      name: `L'imaginarium de Pomme`,
    },
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={siteUrl} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

HomepageMetatags.propTypes = {
  title: T.string,
  description: T.string,
}

export default HomepageMetatags
