import styled from 'styled-components'

import { breakpoints } from 'utils/breakpoints'

export const Description = styled.p`
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 16px;
`

export const Keywords = styled.span`
  font-family: Montserrat;
`

export const MainPages = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px auto;

  & a {
    margin: 0px 8px;
  }

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`
