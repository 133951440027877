import React, { useState, useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import * as R from 'ramda'
import { Link } from 'gatsby'
import T from 'prop-types'

import Layout from 'components/Layout'
import CommonMetatags from 'components/metatags/CommonMetatags'
import HomepageMetatags from 'components/metatags/HomepageMetatags'
import Posts from 'components/Posts'
import TagsSelector from 'components/TagsSelector'

import * as S from './HomepageTemplate.styles'

function HomepageTemplate({ data }) {
  const {
    logo,
    tags: { edges: tags },
    posts: { nodes: posts },
  } = data

  const tutorialPosts = useMemo(
    () =>
      posts.filter((post) => {
        const postTags = post.tags.nodes.map(({ slug }) => slug)
        const nonTutorialTags = ['points', 'broderie', 'savoir-faire']
        return R.intersection(postTags, nonTutorialTags).length === 0
      }),
    [posts]
  )

  const [displayedPosts, setDisplayedPosts] = useState(tutorialPosts)

  const onSelectedTagChange = useCallback(
    (selectedTag) => {
      if (R.equals(selectedTag, 'tutorials')) {
        return setDisplayedPosts(tutorialPosts)
      }
      const newDisplayedPosts = posts.filter((post) => {
        const tags = R.pathOr([], ['tags', 'nodes'], post).map(R.prop('slug'))
        console.log(tags, selectedTag)
        return tags.includes(selectedTag)
      })
      setDisplayedPosts(newDisplayedPosts)
    },
    [posts]
  )

  return (
    <Layout logo={logo} fluid>
      <CommonMetatags />
      <HomepageMetatags
        title="L'imaginarium de Pomme - Le recyclage créatif et ludique"
        description="Crochet, Recyclage, Trucs et Astuces pour la maison, Créations originales."
      />
      <S.Description>
        <S.Keywords>
          Recyclage - Crochet - Couture
          <br />
          <strong>Tous les tutos sont gratuits</strong>
        </S.Keywords>
        <S.MainPages>
          <Link to="/le-crochet-pas-a-pas/">Le crochet pas à pas</Link> -{' '}
          <Link to="/repertoire-des-points/">Répertoire des points</Link>
        </S.MainPages>
      </S.Description>
      <TagsSelector onSelectedTagChange={onSelectedTagChange} tags={tags} />
      <Posts posts={displayedPosts} />
    </Layout>
  )
}

HomepageTemplate.propTypes = {
  data: T.shape({
    logo: T.shape({}),
    tags: T.shape({
      edges: T.arrayOf(T.shape({})),
    }),
    posts: T.shape({
      nodes: T.arrayOf(T.shape({})),
    }),
  }),
}

export default HomepageTemplate

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      gatsbyImageData(width: 500, placeholder: BLURRED)
    }
  }

  query HomePage {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }

    tags: allWpTag {
      edges {
        node {
          name
          slug
        }
      }
    }

    posts: allWpPost(sort: { fields: [dateGmt], order: DESC }) {
      nodes {
        uri
        title
        tags {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              ...Thumbnail
            }
          }
        }
      }
    }
  }
`
