import styled from 'styled-components'

import { breakpoints } from 'utils/breakpoints'
import TagList from 'components/TagList'

export const List = styled(TagList)`
  display: flex;
  justify-content: center;

  @media ${breakpoints.sm} {
    max-width: 75%;
    margin: auto;
  }
`
