import styled from 'styled-components'
import Grid from '@mui/material/Grid'

import { breakpoints } from 'utils/breakpoints'

export const PostsGrid = styled(Grid)`
  max-width: 100%;
  padding: 0;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoints.sm} {
    max-width: 75%;
  }
`

export const NoPostText = styled.p`
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
`
