import React from 'react'
import T from 'prop-types'

import * as R from 'ramda'
import { getImage } from 'gatsby-plugin-image'

import Tag from 'components/Tag'
import TagList from 'components/TagList'

import * as S from './Post.styles'

function Post({ post }) {
  const { uri, title, featuredImage } = post
  const tags = R.pathOr([], ['tags', 'nodes'], post)
  const image = featuredImage?.node?.localFile
    ? getImage(featuredImage?.node?.localFile)
    : null

  return (
    <S.PostGrid component="li" item xs={12} sm={6} md={6} lg={3}>
      <S.Link href={uri}>
        {image && <S.Image image={image} alt={featuredImage?.node?.altText} />}
        <S.Text>
          <S.Title>{title}</S.Title>
          {tags.length > 0 && (
            <TagList>
              {tags.map((tag, index) => (
                <Tag key={index} tag={tag} />
              ))}
            </TagList>
          )}
        </S.Text>
      </S.Link>
    </S.PostGrid>
  )
}

Post.propTypes = {
  post: T.shape({
    uri: T.string,
    title: T.string,
    featuredImage: T.shape({
      node: T.shape({
        localFile: T.shape({}),
        altText: T.string,
      }),
    }),
  }),
}

export default Post
